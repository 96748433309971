




















































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import WorkSession from '@/models/WorkSession';
import {namespace} from 'vuex-class';
import User from '@/models/User';
import moment, {Moment} from 'moment';
import {convertMs} from '@/helper/JobTimeHelper';
import UserRole from '@/models/user-attributes/UserRole';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import {WorkSessionFilterData} from '@/helper/WorksessionFilterData';
import i18n from '@/i18n';
import Gender from '@/models/user-attributes/Gender';
import EmploymentType from '@/models/user-attributes/EmploymentType';
import Denomination from '@/models/user-attributes/Denomination';
import PaymentType from '@/models/user-attributes/PaymentType';
import BillingDelivery from '@/models/user-attributes/BillingDelivery';
import {Permission} from '@/misc/enums/permission.enum';

const JobStore = namespace('job');
const UserStore = namespace('user');
const CustomerStore = namespace('customer');
const CompanyStore = namespace('company');

@Component({
  components: {
    SideCardComponent: () => import(
      /* webpackChunkName: "SideCardComponent" */
      '@/components/shared/SideCard/SideCard.component.vue'),
    UserInitialsComponent: () => import(
      /* webpackChunkName: "UserInitialsComponent" */
      '@/components/user/UserInitials.component.vue'),
    TimeTrackingFilterComponent: () => import(
      /* webpackChunkName: "TimeTrackingFilterComponent" */
      '@/components/time-tracking/TimeTrackingFilter.component.vue'),
    TimeTrackingSidebarContentComponent: () => import(
      /* webpackChunkName: "TimeTrackingSidebarContentComponent" */
      '@/components/time-tracking/TimeTrackingSidebarContent.component.vue'),
    TimeTrackingTableComponent: () => import(
      /* webpackChunkName: "TimeTrackingSidebarContentComponent" */
      '@/components/time-tracking/TimeTrackingTable.component.vue'),
    TableComponent: () => import(
      '@/components/shared/table/Table.component.vue'),
  },
})
export default class TimeTrackingOverviewView extends Vue {
  //region Action, Mutations & Getter
  @JobStore.Action('getWorkSessionsAction')
  public getWorkSessionsAction!: (filterSettings: WorkSessionFilterData) => Promise<WorkSession[]>;
  @CustomerStore.Action('loadCustomerAction')
  private loadCustomerAction!: (customerId: string) => Promise<Customer>;
  @CustomerStore.Action('loadLocationAction')
  private loadLocationAction!: (payload: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @UserStore.Getter('users')
  private _users!: User[];
  @UserStore.Action('loadUsersAction')
  private loadUsersAction!: (payload: { companyId: string }) => Promise<User[]>;
  @UserStore.Action('loadUserAction')
  private loadUserAction!: (userId: string) => Promise<User>;
  @CustomerStore.Getter('customers')
  private _customers!: Customer[];
  @CustomerStore.Action('loadCustomersAction')
  private loadCustomersAction!: (companyId: string) => Promise<Customer[]>;
  private searchString: string = '';
  @JobStore.Action('loadWorkSessionAction')
  public loadWorkSessionAction!: (workSessionId: string) => Promise<WorkSession>;
  @UserStore.Getter('gender')
  private _gender!: Gender[];
  @UserStore.Getter('roles')
  private _roles!: UserRole[];
  @UserStore.Getter('employmentTypes')
  private _employmentTypes!: EmploymentType[];
  @UserStore.Getter('denominations')
  private _denominations!: Denomination[];
  @UserStore.Getter('paymentTypes')
  private _paymentTypes!: PaymentType[];
  @UserStore.Getter('billingDeliveries')
  private _billingDeliveries!: BillingDelivery[];
  @UserStore.Action('loadUsersAttributesAction')
  private loadUsersAttributesAction!: () => Promise<void>;
  @UserStore.Action('loadUserRolesAction')
  private loadRolesAction!: (payload: { companyId: string }) => Promise<void>;
  //endregion

  //region API Arrays and Data-Table specific things
  private get importantKeys() {
    return {employees: User, object: String, date: String};
  }

  private readonly screenWidth: string = screen.width - 90 - 72 + 'px';

  //region API Arrays and Data-table specific things
  /**
   * Current Date selected date, default is today's date.
   */
  public currentDate!: Moment;
  /**
   * Current Week, default is the this week of the year
   */
  public currentWeek: number = 0;
  /**
   * Current year, default this year
   */
  public currentYear: number = 0;
  /**
   * Current Month, default is the this month of the year
   */
  public currentMonth: number = 0;
  /**
   * Max weeks of current year
   */
  public maxWeeks!: number;
  /**
   * Max Months of year year
   */
  public maxMonths: number = 12;
  /**
   * All available Customer Locations
   */
  public customerLocations: Location[] = [];
  /**
   * Loaded Worksessions
   */
  public worksessions: WorkSession[] = [];
  /**
   * Boolean to set the Table loading
   */
  public isLoading = true;
  //endregion

  //region Selected Filter Values
  /**
   * Current Filter Settings
   * Default is only the company
   */
  public currentFilter!: WorkSessionFilterData;
  /**
   * Current Selected TimeFrame Filter
   * default is week
   */
  public currentTimeFilter: string = 'week';
  /**
   * Current Selected Customer in
   * the filter settings menu
   */
  public customers: Customer[] | null = [];
  /**
   * Current Selected Employees
   * in the filter settings
   */
  public employees: User[] | null = [];
  /**
   * Current selected managers
   * in the filter settings
   */
  public managers: User[] | null = [];
  /**
   * Current Selected Location Object in
   * the filter settings menu
   */
  public currentObjects: Location[] | null = [];
  /**
   * Currently selected Worksession
   * will be set during an click on a table row
   */
  public selectedWorksession: WorkSession | null = null;
  public filterHasImages: boolean = false;
  public filterHasComment: boolean = false;
  public filterManual: boolean = false;
  /**
   * Sets the Worksession detail dialog
   * active or not
   */
  private selectedUser?: User;
  private loadedLocation: Location | null = null;
  //endregion

  //region Date Picker Values
  /**
   * Internal date start string (necessary for picker use)
   */
  public dateStartPickerValue: string = '';
  /**
   * Internal date end string (necessary for picker use)
   */
  public dateEndPickerValue: string = '';
  //endregion

  /**
   * Current Compand Id
   */
  private companyId!: string;

  /**
   * Get all users with manager role
   */
  public get availableManagers(): User[] {
    return this._users.filter((user) =>
      (user.role?.isLocationManager) || (user.role!.isTenantAdmin && user.active));
  }

  /**
   * Get all Users with the employee role
   */
  public get availableUsers(): User[] {
    // workSession create, because these users can create a workSession and work
    return this._users.filter((user) =>
      user.role!.hasPermission(Permission.WORKSESSION_CREATE));
  }

  /**
   * Checks if start and end date
   * are selected
   */
  public get isTimeframeSelected() {
    return this.dateStartPickerValue.length > 0 && this.dateEndPickerValue.length > 0;
  }

  /**
   * Checks if starttime is selected
   */
  public get hasStartTime() {
    return this.dateStartPickerValue.length > 0;
  }

  /**
   * Returns the name which should get displayed
   * inside the dialog title
   * if a user opened a worksession dialog
   */
  public get selectedWorksessionName(): string {
    // no worksession selected return empty
    if (!this.selectedWorksession) {
      return '';
    }

    // Collect all names
    const firstName = (this.selectedWorksession.user as User).firstName;
    const lastName = (this.selectedWorksession.user as User).lastName;
    const locationName = this.getLocationName(this.selectedWorksession);

    // Return localized string
    return this.$t('TIMETRACKING.DIALOG.TITLE', {
      name: `${firstName} ${lastName}`,
      location: locationName,
    }).toString();
  }

  /**
   * Gets the current i18n locale
   */
  public get locale(): string {
    return this.$i18n.locale;
  }

  /**
   * Checks if Filter is set to Individual
   */
  private get isIndividual(): boolean {
    return this.currentTimeFilter.length > 0
      && this.currentTimeFilter === 'individual';
  }

  /**
   * Checks if Filter is set to Weekly
   */
  private get isWeekly(): boolean {
    return this.currentTimeFilter.length > 0
      && this.currentTimeFilter === 'week';
  }

  /**
   * Checks if Filter is set to Daily
   */
  private get isDaily(): boolean {
    return this.currentTimeFilter.length > 0
      && this.currentTimeFilter === 'day';
  }

  /**
   * Checks if Filter is set to Monthly
   */
  private get isMonthly(): boolean {
    return this.currentTimeFilter.length > 0
      && this.currentTimeFilter === 'month';
  }

  /**
   * Builds an array of Table Headers
   * for use in the v-data-table on this page
   * IMPORTANT: The width needs tp sum up to 100%. Otherwise, the table divider does not divide the row but is appended to the row
   */
  private get tableHeaders(): any {
    return [
      {text: this.$t('TIMETRACKING.HEADER.CUSTOMER'), value: 'customer', width: '17%'},
      {text: this.$t('TIMETRACKING.HEADER.OBJECT'), value: 'location', width: '17%'},
      {text: this.$t('TIMETRACKING.HEADER.EMPLOYEES'), value: 'user', width: '16%'},
      {text: this.$t('TIMETRACKING.HEADER.DATE'), value: 'date', width: '10%'},
      {text: this.$t('TIMETRACKING.HEADER.TIME'), value: 'time', width: '10%'},
      {text: this.$t('TIMETRACKING.HEADER.TARGETTIME'), value: 'target-time', width: '10%'},
      {text: this.$t('TIMETRACKING.HEADER.DELTA'), value: 'delta', width: '10%'},
      {text: this.$t('TIMETRACKING.HEADER.COMMENTS'), value: 'comments', width: '10%'},
    ];
  }

  /**
   * Builds an string of csv-export headers
   * for use in the @onExportClicked method
   */
  private get exportHeaders(): string {
    // header for the csv
    const exportHeader: string[] = [
      (this.$t('GENERAL.FIRST_NAME')).toString(),
      (this.$t('GENERAL.LAST_NAME')).toString(),
      (this.$t('GENERAL.PHONE')).toString(),
      (this.$t('GENERAL.EMAIL')).toString(),
      (this.$t('GENERAL.PERSONNEL_NUMBER')).toString(),
      (this.$t('GENERAL.STREET')).toString(),
      (this.$t('GENERAL.HOUSE_NO')).toString(),
      (this.$t('GENERAL.POSTAL_CODE')).toString(),
      (this.$t('GENERAL.CITY')).toString(),
      (this.$t('GENERAL.ADDRESS_ADDITION')).toString(),
      (this.$t('USER_DETAIL.BIRTHDATE')).toString(),
      (this.$t('USER_DETAIL.NATIONALITY.NATIONALITY')).toString(),
      (this.$t('USER_DETAIL.GENDER.GENDER')).toString(),
      (this.$t('GENERAL.ROLE')).toString(),
      (this.$t('USER_DETAIL.JOB_DESCRIPTION')).toString(),
      (this.$t('USER_DETAIL.RESIDENCE_PERMIT_EXPIRATION')).toString(),
      (this.$t('USER_DETAIL.WORK_PERMIT_EXPIRATION')).toString(),
      (this.$t('USER_DETAIL.START_DATE')).toString(),
      (this.$t('USER_DETAIL.END_DATE')).toString(),
      (this.$t('USER_DETAIL.TIME_LIMIT')).toString(),
      (this.$t('USER_DETAIL.EMPLOYMENT_TYPE.EMPLOYMENT_TYPE')).toString(),
      (this.$t('USER_DETAIL.PAYMENT_TYPE.PAYMENT_TYPE')).toString(),
      (this.$t('USER_DETAIL.PENSION_INSURANCE.PENSION_INSURANCE')).toString(),
      (this.$t('USER_DETAIL.SOCIAL_SECURITY_NUMBER')).toString(),
      (this.$t('USER_DETAIL.TAX_ID')).toString(),
      (this.$t('USER_DETAIL.TAX_CLASS')).toString(),
      (this.$t('USER_DETAIL.CHILD_ALLOWANCE')).toString(),
      (this.$t('USER_DETAIL.DENOMINATION.DENOMINATION')).toString(),
      (this.$t('USER_DETAIL.HEALTH_INSURANCE')).toString(),
      (this.$t('USER_DETAIL.IBAN')).toString(),
      (this.$t('USER_DETAIL.BANK_NAME')).toString(),
      (this.$t('USER_DETAIL.PAYMENT_INTERVAL.PAYMENT_INTERVAL')).toString(),
      (this.$t('USER_DETAIL.BILLING_DELIVERY.BILLING_DELIVERY')).toString(),
      (this.$t('TIMETRACKING.HEADER.CUSTOMER_COLON_NAME')).toString(),
      (this.$t('TIMETRACKING.HEADER.CUSTOMER_COLON_STREET')).toString(),
      (this.$t('TIMETRACKING.HEADER.CUSTOMER_COLON_HOUSE_NO')).toString(),
      (this.$t('TIMETRACKING.HEADER.CUSTOMER_COLON_POSTAL_CODE')).toString(),
      (this.$t('TIMETRACKING.HEADER.CUSTOMER_COLON_CITY')).toString(),
      (this.$t('TIMETRACKING.HEADER.CUSTOMER_COLON_COUNTRY')).toString(),
      (this.$t('TIMETRACKING.HEADER.OBJECT_COLON_NAME')).toString(),
      (this.$t('TIMETRACKING.HEADER.OBJECT_COLON_STREET')).toString(),
      (this.$t('TIMETRACKING.HEADER.OBJECT_COLON_HOUSE_NO')).toString(),
      (this.$t('TIMETRACKING.HEADER.OBJECT_COLON_POSTAL_CODE')).toString(),
      (this.$t('TIMETRACKING.HEADER.OBJECT_COLON_CITY')).toString(),
      (this.$t('TIMETRACKING.HEADER.OBJECT_COLON_COUNTRY')).toString(),
      (this.$t('TIMETRACKING.HEADER.DATE')).toString(),
      (this.$t('GENERAL.START_TIME')).toString(),
      (this.$t('GENERAL.END_TIME')).toString(),
      (this.$t('TIMETRACKING.HEADER.DURATION')).toString(),
      (this.$t('TIMETRACKING.HEADER.MANUAL_TIME_CHANGE')).toString(),
    ];

    // content of the csv
    return exportHeader.join(';') + '\n';
  }

  public created() {
    // Get and set current days date.
    const today = moment();
    this.currentDate = today;

    // Get current week
    this.currentWeek = today.isoWeek();
    this.maxWeeks = today.weeksInYear();
    this.currentMonth = today.month() + 1;

    // Get current Year
    this.currentYear = today.year();

    // Get passed company Id
    this.companyId = this.$route.params.companyId;

    // Set Default Filter
    this.currentFilter = {
      company: this.companyId,
      endTimeAtFrom: today.startOf('week').toISOString(),
      endTimeAtTo: today.endOf('week').toISOString(),
    };
  }

  public async mounted() {
    // Load all necessary relations like customers, users and object locations
    try {
      // Resolve all customer and user promises
      const resolvedPromises = await Promise.all([
        this.loadCustomersAction(this.companyId),
        this.loadUsersAction({companyId: this.companyId}),
        this.getWorkSessionsAction(this.currentFilter),
        this.loadUsersAttributesAction(),
        this.loadRolesAction({companyId: this.$route.params.companyId}),
      ]);

      // Get
      const worksessions = resolvedPromises[2] as WorkSession[];

      // Set Worksessions for this call
      this.worksessions = worksessions.filter((workSession) => workSession.duration != null);
    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }

    // Set table loading to false
    this.isLoading = false;
  }

  public onWorkSessionUpdate() {
    this.onFilterChanged();
  }

  /**
   * If a new manager was selected,
   * loads all locations and make it available
   * @param managers The managers to filter
   */
  public async onManagerSelected(managers: User[]) {
    // Set Manager
    this.managers = managers;

    // Check if customer has data, if not return
    if (!this.managers || this.managers!.length <= 0) {
      return;
    }
  }

  /**
   * If a new customer was selected,
   * loads all locations and makes it available
   * @param customers The added customer
   */
  public async onCustomerSelected(customers: Customer[]) {
    // Set Customers
    this.customers = customers;

    // Reset locations
    this.customerLocations = [];

    // Check if customer has data, if not return
    if (!this.customers || this.customers!.length <= 0) {
      return;
    }

    // Go through all selected customers, gather all location promises
    const customerPromises: Array<Promise<Customer>>
      = this.customers.map((element) => this.loadCustomerAction(element.id!));

    // Await all Customer Promises
    const resolvedCustomers: Customer[] = await Promise.all(customerPromises);

    // set current loaded locations
    this.customerLocations = ([] as Location[]).concat(...resolvedCustomers.map((customer: Customer) =>
      customer.locations));
  }

  /**
   * If a new employee was selected,
   * loads all locations and makes it available
   * @param employees The added employee
   */
  public async onEmployeeSelected(employees: User[]) {
    // Set Employee
    this.employees = employees;
  }

  /**
   * Object Update Listener
   * updates local locations by using
   * the sent ones from the filter component
   * @param objects The location to filter
   */
  public onObjectChanged(objects: Location[]) {
    this.currentObjects = objects;
  }

  /**
   * Gets executed if the clear button
   * for a customer was pressed
   */
  public onCustomerClearClicked() {
    // Reset locations and current selected object
    this.customers = [];
    this.customerLocations = [];
    this.currentObjects = [];
  }

  /**
   * Checks if this worksession includes
   * actts with sessionactts that has comments inside
   */
  public hasComment(item: WorkSession): boolean {
    return !!item.comment;
  }
  /**
   * Checks if this worksession includes
   * actts with sessionactts that has images inside
   */
  public hasImages(item: WorkSession): boolean {
    return item.hasImages!;
  }

  /**
   * Formats two dates to a time format hh:mm
   * @param startDate The start date
   * @param endDate The end date
   */
  public getFormattedTime(startDate: Date, endDate: Date) {
    // Get Moment Object from start and end
    const start = moment(startDate);
    const end = moment(endDate);

    // Date is not valid, the job wasnt worked on
    if (!start.isValid() || !end.isValid()) {
      return this.$t('TIMETRACKING.MESSAGES.NOT_DETERMINED').toString();
    }

    // Return Time representation
    return `${start.format('HH:mm')} - ${end.format('HH:mm')}`;
  }

  /**
   * Clicked on Today Button
   */
  public onTodayClicked() {
    // Set Filter to Day and change currentDate to this day
    this.currentTimeFilter = 'day';
    this.currentDate = moment();

    // Reload Data
    this.onFilterChanged();
  }

  /**
   * Previous arrow was clicked
   * updates either the day or the week
   * depending on the selected time frame
   */
  public async onPreviousClicked() {
    // is Daily update Date, by subtracting one day
    if (this.isDaily) {
      this.currentDate = this.currentDate.subtract(1, 'day');
    }

    // is Monthly update Date, by subtracting one month
    if (this.isMonthly) {
      const subtractValue = this.currentMonth - 1;

      if (subtractValue < 1) {
        // Decrease Year
        this.currentYear--;

        // Set Month to December
        this.currentMonth = 12;
      } else {
        this.currentMonth = subtractValue;
      }
    }

    // is Weekly update week count by subtracting one
    if (this.isWeekly) {
      this.currentDate = this.currentDate.subtract(7, 'day');
      const subtractValue = this.currentWeek - 1;

      // If smaller than one, change year to previous
      // and change week to max week of this year
      if (subtractValue < 1) {
        // Decrease Year
        this.currentYear--;

        // Get Week Count of specific Year
        const weeksInYear = moment().set('year', this.currentYear);

        // Set Week Value for this year
        this.maxWeeks = weeksInYear.isoWeeksInYear();
        this.currentWeek = weeksInYear.isoWeeksInYear();
      } else {
        this.currentWeek = subtractValue;
      }
    }

    // Execute Filter Changes
    await this.onFilterChanged();
  }

  public onNextClicked() {
    // is Daily update Date, by adding one day
    if (this.isDaily) {
      this.currentDate = this.currentDate.add(1, 'day');
    }

    // is Weekly update week count by adding one
    // and checking if its above the max week count
    // if the value is above, set to max weeks
    if (this.isWeekly) {
      this.currentDate = this.currentDate.add(7, 'day');
      const addValue = this.currentWeek + 1;

      // Check if Above Max Weeks
      // Then switch to next year
      if (addValue > this.maxWeeks) {
        // Increase Year
        this.currentYear++;

        // Get Week Count of specific Year
        const weeksInYear = moment().set('year', this.currentYear);

        // Set Week Value for this year
        this.maxWeeks = weeksInYear.isoWeeksInYear();
        this.currentWeek = 1;
      } else {
        this.currentWeek = addValue;
      }
    }

    // is Month update by adding one all the way to 12
    if (this.isMonthly) {
      const addValue = this.currentMonth + 1;

      // Check if above December
      if (addValue > this.maxMonths) {
        // Increase Year
        this.currentYear++;

        // Set Month to January
        this.currentMonth = 1;
      } else {
        this.currentMonth = addValue;
      }
    }

    // Execute Filter Changes
    this.onFilterChanged();
  }

  public getCurrentFilterSettings(): WorkSessionFilterData {
    // Build Filter Object
    const filter = {...this.currentFilter};

    // Set Customers to Filter
    filter.customers = this.customers && this.customers.length > 0
      ? this.customers!.map((customer) => customer.id!)
      : null;

    // Set Users to Filter
    filter.users = this.employees && this.employees.length > 0
      ? this.employees!.map((employee) => employee.id!)
      : null;

    // Set managers to Filter
    filter.managers = this.managers && this.managers.length > 0
      ? this.managers!.map((manager) => manager.id!)
      : null;

    // Set Locations to Filter
    filter.locations = this.currentObjects && this.currentObjects.length > 0
      ? this.currentObjects!.map((location) => location.id!)
      : null;

    // individual time frame
    if (this.isIndividual) {
      const fromDate = moment.utc(this.dateStartPickerValue);
      filter.endTimeAtFrom = fromDate.startOf('day').toISOString();

      // Check if End Date was Set
      // if not use today's date
      const toDate = this.dateEndPickerValue.length > 0
        ? moment.utc(this.dateEndPickerValue)
        : moment();

      // Set Created To Date
      filter.endTimeAtTo = toDate.endOf('day').local().toISOString();
    }

    // Selected day as Timeframe
    if (this.isDaily) {
      const startOfDay = moment(this.currentDate).startOf('day');
      const endOfDay = moment(this.currentDate).endOf('day');
      filter.endTimeAtFrom = startOfDay.toISOString();
      filter.endTimeAtTo = endOfDay.toISOString();
    }

    // Selected Month as Timeframe
    if (this.isMonthly) {
      // Get Current Week
      const month = moment().set('month', this.currentMonth - 1).set('year', this.currentYear);

      // Get start and end of Week
      const startOfMonth = moment(month).startOf('month');
      const endOfMonth = moment(month).endOf('month');

      // Set From and To for current filter
      filter.endTimeAtFrom = startOfMonth.toISOString();
      filter.endTimeAtTo = endOfMonth.toISOString();
    }

    // Selected Week as Timeframe
    if (this.isWeekly) {
      // Get Current Week
      const week = moment().set('year', this.currentYear).set('week', this.currentWeek);

      // Get start and end of Week
      const startOfWeek = moment(week).startOf('week');
      const endOfWeek = moment(week).endOf('week');

      // Set From and To for current filter
      filter.endTimeAtFrom = startOfWeek.toISOString();
      filter.endTimeAtTo = endOfWeek.toISOString();
    }

    // Misc booleans in filter
    filter.hasComments = this.filterHasComment;
    filter.hasImages = this.filterHasImages;
    filter.hasDuration = this.filterManual;
    return filter;
  }

  /**
   * Executes Filter Changes and reloads data
   */
  public async onFilterChanged() {

    // set loading state to true
    this.isLoading = true;

    // Load worksessions with edited filter
    const unfilteredSessions = await this.getWorkSessionsAction(this.getCurrentFilterSettings());

    // Filter Sessions by duration
    this.worksessions = unfilteredSessions.filter((worksSession) => worksSession.duration != null);

    // Set loading to false
    this.isLoading = false;
  }

  /**
   * Will be executed if a user clicks on a table row
   * should open a modal where you can see the job information
   */
  public async onRowClicked(workSession: WorkSession) {
    // load whole passed object and set as Selected Worksession
    await this.loadWorkSessionAction(workSession.id).then((value) => {
      this.selectedWorksession = value;
    });
    // Show Side Card
    this.showSideCard = true;
  }

  /**
   * Gets localized Duration Time from Worksession item
   */
  public getDurationHumanized(duration: number) {
    const d = moment.duration(duration);
    return `${d.hours()}:${(Math.abs(d.minutes()) + '').padStart(2, '0')} ${this.$t('GENERAL.UNITS.ABBRV.HOUR')}`;
  }

  public hasDurationTracked(item: WorkSession): boolean {
    return !(!item.durationTracked || item.canceledByReason);
  }

  public hasDuration(item: WorkSession): boolean {
    return !(!item.duration || item.canceledByReason);
  }

  /**
   * Listener for Date Value Changes
   * sets the end and start picker values
   * e.g for displaying at the top
   */
  public onDateValuesChanged(dates: { dateStart: string, dateEnd: string }) {
    this.dateEndPickerValue = dates.dateEnd;
    this.dateStartPickerValue = dates.dateStart;
  }

  /**
   * Calculates the Time between two duration milliseconds
   * returns a string with hours and minutes
   * @param duration
   * @param durationTracked
   */
  public getDurationDeltaString(duration: number, durationTracked: number) {
    // Calculate duration delta
    const delta = Math.abs(duration - durationTracked);

    // convert delta to a neat time object
    const time = convertMs(delta);

    // pad absolute hours and minutes at start to have two numbers at all times
    const hours = (time.hour + (time.day * 24)).toString();
    const minutes = time.minute.toString().padStart(2, '0');

    // check if its negative or positive.
    let prefixString = '';
    if (durationTracked < duration) {
      prefixString = '-';
    } else if (durationTracked > duration) {
      prefixString = '+';
    }

    // return formatted string
    const abbr = this.$t('GENERAL.UNITS.ABBRV.HOUR').toString();
    return `${prefixString}${hours}:${minutes} ${abbr}`;
  }

  /**
   * Gets the Name of the associated location
   * for this worksession
   * @param workSession
   */
  public getLocationName(workSession: WorkSession): string {
    // No Actts we dont know the location name.
    if (!workSession.location) {
      return this.$t('CLEANTIME_OVERVIEW.LOCATIONS.UNKNOWN').toString();
    }

    return workSession.location.name!;
  }

  /**
   * Returns the current status of a worksession
   * e.g if aborted shows Abort Message
   */
  public getWorkSessionRealTimeStatus(item: WorkSession): string {
    if (this.isWorkSessionAborted(item)) {
      return this.$t('JOB_OVERVIEW.JOB_CANCELLED').toString();
    }

    return this.$t('JOB_OVERVIEW.STILL_RUNNING').toString();
  }

  public isWorkSessionAborted(item: WorkSession) {
    return !!item.canceledByReason;
  }

  private get getCustomers() {
    return this._customers;
  }

  /**
   * Gets the Name of the associated customer
   * for this worksession
   * @param workSession
   */
  public getCustomerName(workSession: WorkSession): string {
    // No Actts we dont know the location name.
    const tmp = this.getCustomers.find((entry) => entry.id === workSession.customerId)?.name;
    if (!tmp) {
      return this.$t('CLEANTIME_OVERVIEW.LOCATIONS.UNKNOWN').toString();
    }
    return tmp;
  }

  /**
   * Custom Sorter
   * for sorting worksessions in the data table
   */
  public async workSessionSorter(items: WorkSession[], sortBy: string[] | undefined, sortDesc: boolean[] | undefined):
    Promise<WorkSession[]> {
    // return all items if no sorting is enabled
    if (sortBy === undefined || sortDesc === undefined) {
      return items;
    }

    // Get Table Row and orientation to sort for
    const sortByHeader: string | undefined = sortBy![0];
    const sortByDesc: boolean | undefined = sortDesc![0];

    // Sort according to the header
    switch (sortByHeader) {
      case 'user':
        items = items.sort((a, b) => {
          const bUser = (b.user as User);
          const aUser = (a.user as User);

          if (sortByDesc) {
            return aUser.fullName!.localeCompare(bUser.fullName);
          }
          return bUser.fullName!.localeCompare(aUser.fullName);
        });
        break;
      case 'object':
        items = items.sort((a, b) => {
          const bLocation = (b.location as Location);
          const aLocation = (a.location as Location);

          if (sortByDesc) {
            return aLocation.name!.localeCompare(bLocation.name!);
          }
          return bLocation.name!.localeCompare(aLocation.name!);
        });
        break;
      case 'date':
        items = this.dateComparison(items, sortByDesc, 'createdAt');
        break;
      case 'targettime':
        items = this.numberComparison(items, sortByDesc, 'duration');
        break;
      case 'realtime':
        items = this.numberComparison(items, sortByDesc, 'durationTracked');
        break;
      case 'delta':
        items = items.sort((a, b) => {
          // Calculate Duration in ms
          let stateB = b.durationTracked - b.duration;
          let stateA = a.durationTracked - a.duration;

          // Check if duration was tracked
          if (!this.hasDurationTracked(a)) {
            stateA = -Infinity;
          } else if (!this.hasDurationTracked(b)) {
            stateB = -Infinity;
          }

          // Return Sort Value
          return sortByDesc
            ? stateB - stateA
            : stateA - stateB;
        });
        break;
      case 'comments':
        items = items.sort((a, b) => {
          // Get Active and InviteAccepted in one value for both a and b
          let miscStateA = Number(a.hasImages!) + Number(a.hasComments!);
          let miscStateB = Number(b.hasImages!) + Number(b.hasComments!);

          // Check if a is not active, then set value to 0
          if (!a.hasImages && a.hasComments) {
            miscStateA = 0;
          }

          // Check if b is not active, then set value to 0
          if (!b.hasImages && b.hasComments) {
            miscStateB = 0;
          }

          // Check if b has neither of both
          if (!b.hasComments && !b.hasImages) {
            miscStateB = -1;
          }

          // Check if a has neither of both
          if (!a.hasComments && !a.hasImages) {
            miscStateA = -1;
          }

          // Return Sort Value
          return sortByDesc
            ? miscStateA - miscStateB
            : miscStateB - miscStateA;
        });
        break;
    }

    return items;
  }

  /**
   * Event Listener for Time Filter changes
   * e.g week, day or timeframe
   */
  private onTimeFilterUpdated(timeFilter: string) {
    this.currentTimeFilter = timeFilter;

    // Reset Values if TimeFilter was changed
    const today = moment();
    this.currentDate = today;
    this.currentYear = today.year();
    this.maxWeeks = today.isoWeeksInYear();
    this.currentWeek = today.week();
    this.currentMonth = today.month() + 1;
  }

  /**
   * Event Listener for Misc Boolean changes
   */
  private onFilterImageComment(data: { hasComments: boolean, hasImages: boolean, manual: boolean }) {
    this.filterHasImages = data.hasImages;
    this.filterHasComment = data.hasComments;
  }

  /**
   * Event Listener for Misc Boolean changes
   */
  private onFilterManualComment(data: { manual: boolean }) {
    this.filterManual = data.manual;
  }

  /**
   * Compares given row as number with other row
   * @param items array where the comparison should happen
   * @param sortByDesc if its descending or ascending
   * @param comparison which row should be looked at
   * @private
   */
  private numberComparison(items: WorkSession[], sortByDesc: boolean, comparison: string) {
    return items.sort((a, b) => {
      if (sortByDesc) {
        return a[comparison] as number - b[comparison] as number;
      }
      return b[comparison] as number - a[comparison] as number;
    });
  }

  /**
   * Compares given row as Date with other row
   * @param items array where the comparison should happen
   * @param sortByDesc if its descending or ascending
   * @param comparison which row should be looked at
   * @private
   */
  private dateComparison(items: WorkSession[], sortByDesc: boolean, comparison: string) {
    return items.sort((a, b) => {
      if (sortByDesc) {
        return moment(a[comparison]) < moment(b[comparison]) ? -1 : 1;
      }
      return moment(b[comparison]) < moment(a[comparison]) ? -1 : 1;
    });
  }

  private showSideCard = false;
  private expandCustomerAddress = false;

  /**
   * Clicked on Export Button
   */
  private async onExportClicked(items: WorkSession[]) {
    // no workSessions, no export
    if (!items?.length) {
      return;
    }
    // try to download the worksessions you want to export
    try {
      // content of the csv
      const bom = decodeURIComponent('%EF%BB%BF'); // "\uFEFF\n";
      let csvContent = '' + bom;
      csvContent += this.exportHeaders;

      for (const i of items) {
        const tmp = await this.getUser(i.user!);
        this.loadedLocation = null;
        // @ts-ignore
        this.loadedLocation = await this.loadLocationAction({locationId: i.location.id, shouldBeStored: true});
        csvContent += this.getUserAndWorksessionDetails(tmp, i);
      }
      const sortedWorksessions = await this.workSessionSorter(this.worksessions, ['date'], [true]);
      // set up the csv-file
      const filename = process.env.VUE_APP_TITLE
        + '_' + this.$t('GENERAL.TIME_TRACKING')
        + '_' + this.formattedDate(sortedWorksessions[0].createdAt)
        + '-' + this.formattedDate(sortedWorksessions[sortedWorksessions.length - 1].createdAt) + '.csv';
      const blob = new Blob([csvContent], {type: 'text/csv;charset=UTF-16LE;'});
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', blobUrl);
      link.setAttribute('download', filename);
      link.click();
    } catch (e: any) {
      // show error with this Status `TIME_TRACKING.ERRORS.${e.status}`
      this.$notifyErrorSimplified(e);
    }
  }

  /**
   * checks if data are undefined or null and returns an empty string if they are
   * @param value to be checked
   * @private
   */
  private check(value: any) {
    if (typeof value === 'undefined' || !value || value === 'Invalid date') {
      return '';
    }
    return value;
  }

  /**
   * returns true if the worksession has been altered, or if it was artificially created
   * @param workSession the current workSession
   */
  private wasEdited(workSession: WorkSession) {
    if (!workSession.durationTracked || workSession.vcOrigin) {
      return true;
    }

    // duration has a puffer of one minute to not be counted as edited
    const duration = moment(workSession.endTime).diff(moment(workSession.durationSetAt));
    return Math.abs(duration) > 1000 * 60;
  }

  /**
   * reload a given user
   * @param value to be checked
   * @private
   */
  private async getUser(value: User | string): Promise<User> {
    if (value instanceof User) {
      return await this.loadUserAction(value.id!);
    }
    return await this.loadUserAction(value);
  }

  /**
   * check the given duration
   * @param duration to be checked
   * @private
   */
  private hasManualTime(duration: moment.Duration): string {
    if (!(duration.hours() === 0 && duration.minutes() === 0 && duration.seconds() === 0)) {
      return (this.$t('TIMETRACKING.HEADER.YES')).toString();
    }
    return (this.$t('TIMETRACKING.HEADER.NO')).toString();
  }

  /**
   * returns the details of user and worksession in a string
   * @param value, the user whose details must be specified
   * @param item, the worksession whose details must be specified
   * @private
   */
  private getUserAndWorksessionDetails(value: User, item: WorkSession) {
    const exportUser: string[] | null = [];

    const expUser = value;
    const i = item;

    const tmp = this._customers.find((entry) => entry.id === i.customer!.id);
    const tmp1 = moment.duration(i.duration);

    exportUser[0] = this.check(expUser.firstName);
    exportUser[1] = this.check(expUser.lastName);
    exportUser[2] = this.check(expUser.phone);
    exportUser[3] = this.check(expUser.email);
    exportUser[4] = this.check(expUser.personnelNumber);
    exportUser[5] = this.check(expUser.address?.street);
    exportUser[6] = this.check(expUser.address?.houseNo);
    exportUser[7] = this.check(expUser.address?.postalCode);
    exportUser[8] = this.check(expUser.address?.city);
    exportUser[9] = this.check(expUser.address?.addition);
    exportUser[10] = this.check(expUser.birthday);
    exportUser[11] = this.check(expUser.euResident ?
      this.$t('USER_DETAIL.NATIONALITY.EU_CITIZEN') : this.$t('USER_DETAIL.NATIONALITY.NO_EU_CITIZEN'));
    exportUser[12] = this.check(expUser.gender ? this.$t(`USER_DETAIL.GENDER.${expUser.gender.name.toUpperCase()}`) : null);
    // @ts-ignore
    exportUser[13] = this.check(expUser.role?.name ?? null);
    exportUser[14] = this.check(expUser.jobDescription);
    exportUser[15] = this.check(this.formattedDate(expUser.residencePermitExpirationDate!));
    exportUser[16] = this.check(this.formattedDate(expUser.workPermitExpirationDate!));
    exportUser[17] = this.check(this.formattedDate(expUser.firstDayInCompany!));
    exportUser[18] = this.check(this.formattedDate(expUser.lastDayInCompany!));
    exportUser[19] = this.check(this.formattedDate(expUser.timeLimit!));
    exportUser[20] = this.check(expUser.employmentType ?
      this.$t(`USER_DETAIL.EMPLOYMENT_TYPE.${expUser.employmentType.name.toUpperCase()}`) : null);
    exportUser[21] = this.check(expUser.paymentInterval ? expUser.paymentInterval.toString() : null);
    exportUser[22] = this.check(expUser.pensionInsurance ?
      this.$t('USER_DETAIL.PENSION_INSURANCE.COMPULSORY') : this.$t('USER_DETAIL.PENSION_INSURANCE.FREE'));
    exportUser[23] = this.check(expUser.socialSecurityNumber);
    exportUser[24] = this.check(expUser.taxId);
    exportUser[25] = this.check(expUser.taxClass);
    exportUser[26] = this.check(expUser.childAllowance?.toString());
    exportUser[27] = this.check(expUser.denomination ?
      this.$t(`USER_DETAIL.DENOMINATION.${expUser.denomination.name.toUpperCase()}`) : null);
    exportUser[28] = this.check(expUser.healthInsurance);
    exportUser[29] = this.check(expUser.iban);
    exportUser[30] = this.check(expUser.bankName);
    exportUser[31] = this.check(expUser.paymentInterval ? expUser.paymentInterval?.toString() : null);
    exportUser[32] = this.check(expUser.billingDelivery ?
      this.$t(`USER_DETAIL.BILLING_DELIVERY.${expUser.billingDelivery.name.toUpperCase()}`) : null);
    exportUser[33] = this.check(tmp?.name);
    exportUser[34] = this.check(tmp?.address?.street);
    exportUser[35] = this.check(tmp?.address?.houseNo);
    exportUser[36] = this.check(tmp?.address?.postalCode);
    exportUser[37] = this.check(tmp?.address?.city);
    exportUser[38] = this.check(this.$t(`GENERAL.COUNTRIES.${tmp?.address?.country?.toUpperCase()}`));
    exportUser[39] = this.getLocationName(i);
    exportUser[40] = this.check(this.loadedLocation?.address?.street);
    exportUser[41] = this.check(this.loadedLocation?.address?.houseNo);
    exportUser[42] = this.check(this.loadedLocation?.address?.postalCode);
    exportUser[43] = this.check(this.loadedLocation?.address?.city);
    exportUser[44] = this.check(this.$t(`GENERAL.COUNTRIES.${this.loadedLocation?.address?.country?.toUpperCase()}`));
    exportUser[45] = this.formattedDate(i.createdAt);
    // @ts-ignore
    exportUser[46] = this.formattedTime(i.startTime);
    // @ts-ignore
    exportUser[47] = this.formattedTime(i.endTime);
    // @ts-ignore
    exportUser[48] = `${tmp1.hours()}:${tmp1.minutes()}:${tmp1.seconds()}`;
    exportUser[49] = this.hasManualTime(tmp1);

    return exportUser.join(';') + '\n';
  }

  /**
   * returns a well formatted date
   * @private
   */
  private formattedDate(value: string) {
    const date = moment(value, moment.ISO_8601, true);

    return date.format(i18n.t('GENERAL.DATE_FORMATS.DATE').toString());
  }

  /**
   * returns a well formatted time
   * @private
   */
  private formattedTime(date: Date) {
    // Get Moment Object from date
    const cDate = moment(date);

    // Date is not valid
    if (!cDate.isValid()) {
      return '';
    }

    // Return Time representation
    return `${cDate.format('HH:mm')}`;
  }
}
